const rome = require("@bevacqua/rome")

const ical = require('ical');
const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];


function dragOverHandler(ev) {
  // Prevent default behavior (Prevent file from being opened)
  ev.preventDefault();
}

function  dropHandler(ev) {
  // Prevent default behavior (Prevent file from being opened)
  ev.preventDefault();

  if (ev.dataTransfer.items) {
    for (var i = 0; i < ev.dataTransfer.items.length; i++) {
      if (ev.dataTransfer.items[i].kind === 'file') {
        var file = ev.dataTransfer.items[i].getAsFile();
        console.log('ev.dataTransfer.items file[' + i + '].name = ' + file.name);
        file.text().then(function(string){
          // console.log("ical = ", string);
          const data = ical.parseICS(string);
          const ev = Object.values(data).filter( entry => entry.type == 'VEVENT')[0];
          console.dir(ev);
          console.log(`${ev.summary} is in ${ev.location} on the ${ev.start.getDate()} of ${months[ev.start.getMonth()]} at ${ev.start.toLocaleTimeString('de-DE')}`);
          document.querySelector('#event_name').value = ev.summary;
          document.querySelector('#event_url').value = decodeURIComponent(ev.url); // decodeURIComponent() for UTF-8 encoded URIs
          if( document.querySelector('#event_url').value == 'undefined' ) document.querySelector('#event_url').value = '';
          setDatetimeValue('#event_start', ev.start, 0);
          setDatetimeValue('#event_end', ev.end, 1);
          document.querySelector('#event_description').value = ev.description;
          if( document.querySelector('#event_description').value == 'undefined' ) document.querySelector('#event_description').value = '';

        });
      }
    }
  }
}

function setDatetimeValue(fieldId, date, calIndex) {
  if(date != null) {
    const cal = rome.find(calIndex);
    cal.options({"required": true})
    cal.setValue(date);
    document.querySelector(fieldId).value = cal.getDateString();
  }
}



document.addEventListener("turbo:load", function() {

  let drop_zone = document.getElementById('drop_zone');
  if ( drop_zone ) {
    console.log("ready for dropping on the drop_zone", drop_zone);
    drop_zone.classList.add('droppable');
    drop_zone.innerHTML = "<span>drop .ics file here</span>"
    drop_zone.addEventListener('drop', dropHandler);
    drop_zone.addEventListener('dragover', dragOverHandler);
  }
});

