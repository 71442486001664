const GeoPattern = require("geopattern")

document.addEventListener("turbo:load", function() {
  let outputs = document.querySelectorAll('.show-geopattern');
  for( let o of outputs ) {
    var seed = o.dataset.seed
    var pattern = GeoPattern.generate(seed);
    var url = pattern.toDataUrl();
    var color = pattern.color;
    o.style.backgroundImage = url;
    o.style.backgroundColor = color;

  }

});