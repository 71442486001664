// Entry point for the build script in your package.json
console.log("starting all new and better application.js");
import "@hotwired/turbo-rails";
import "./src/autocomplete";
import "./src/icsupload";
import "./src/datepicker";
import "./src/geopattern";
import "./src/qrcode";
import "./src/placestamp";
import "./src/cropper";
import "./src/burger";

console.log("done with application.jss");
