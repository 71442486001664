document.addEventListener("turbo:load", function () {
  let boxes = document.querySelectorAll(".stamp-placement-box");

  boxes.forEach((box) => {
    box.addEventListener("click", function () {
      placeStampInBox(box);
    });
  });
});

function placeStampInBox(boxElement) {
  if (boxElement) {
    const boxRect = boxElement.getBoundingClientRect();

    console.log(boxRect);

    const stampWidth = 405;
    const stampHeight = 110;

    const boxId = boxElement.getAttribute("data-box-id");

    let overlay = boxElement.closest(".overlay");

    const overlayRect = overlay.getBoundingClientRect();

    const relativeLeft =
      boxRect.left - overlayRect.left + (boxRect.width - stampWidth) / 2;
    const relativeTop =
      boxRect.top - overlayRect.top + (boxRect.height - stampHeight) / 2;

    let imgElement = overlay.querySelector("img");

    let pageId = imgElement.getAttribute("data-pageid");

    console.log(`Page ID from img: ${pageId}`);

    console.log(`Relative Left: ${relativeLeft}`);
    console.log(`Relative Top: ${relativeTop}`);

    let values = {
      left: relativeLeft,
      top: relativeTop,
      width: stampWidth,
      height: stampHeight,
      page_id: pageId,
    };

    console.log(values);
    console.log(typeof values);

    for (let key of ["left", "top", "width", "height", "page_id"]) {
      let new_value = values[key];
      console.log(`setting stamp_${key} to ${new_value}`);
      document.getElementById("stamp_" + key).value = new_value;
    }

    document.getElementsByTagName("form")[0].submit();

    console.log(`Placed stamp in box ${boxId}`);
  } else {
    console.error(`Could not find box elements with id: ${boxId}`);
  }
}
