const QRCode = require('qrcode-svg');
var qrcode_node;

document.addEventListener('turbo:load', () => {
  qrcode_node = document.getElementById('qrcode');
  console.log("this page will display a qrcode in", qrcode_node);

  if (qrcode_node) {

    qrcode_node.addEventListener("turbo:before-frame-render", (function(event){
      event.detail.render = (currentElement, newElement) => {
        code = newElement.innerText;
        if( code.length < 10 ) {
          console.log("event is not current, no qr code.");
          currentElement.innerHTML= "event is not current, no qr code.";
        } else {
          setTimeout(() => qrcode_node.reload(), 1000 * 19); // reload in 19 seconds - also change in lib/event_token.rb
          now = new Date;
          url = window.location.origin + "/redeem?token=" + code;
          console.log(`QRCode for ${url} displayed at ${now.getMinutes()}:${now.getSeconds()}, reload in a minute`);
          currentElement.innerHTML = new QRCode(url).svg();
        }
      }
    }));

  }
});


