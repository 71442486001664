const autocomplete = require('autocompleter');

document.addEventListener('turbo:load', () => {
  const input = document.querySelector('#user-picker');
  const hiddenIdField = document.querySelector('#user-id');

  if (!input) return;

  console.log("there is a user-picker input on this page");

  fetch('/users.json')
    .then((res) => res.json())
    .then((users) => {
      users = users.map((user) => ({
        label: `${user.first_name} ${user.last_name}`,
        value: user.id,
      }));

      autocomplete({
        input: input,
        minLength: 3,
        preventSubmit: true,
        fetch: (text, update) => {
          text = text.toLowerCase();
          const suggestions = users.filter((user) => user.label.toLowerCase().includes(text));
          update(suggestions);
        },
        onSelect: (item) => {
          input.value = item.label;
          hiddenIdField.value = item.value;
        },
      });
    });
});
