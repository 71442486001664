const rome = require("@bevacqua/rome")
const Cropper = require("cropperjs")

let cr = null;

function escape_the_cropper(){
  if(cr){
    console.log("escaped from cropper")
    cr.destroy();
  }
}

function enter_the_cropper() {
  if(cr){
    console.log("done with cropper");
    let values = cr.getCropBoxData();
    console.log(values);
    console.log(typeof values);
    for(let key of ["left", "top", "width", "height"] ) {
      let new_value = values[key];
      console.log(new_value.key)
      document.getElementById("stamp_" + key).value = new_value;
      console.log(`setting stamp_${key} to ${new_value}`);
    }
    cr.destroy();
    console.log("escaped from cropper")
    document.getElementsByTagName('form')[0].submit();
  }
}

function cropper_escape_or_enter(e){
  if(e.key === "Escape") {
    escape_the_cropper()
  }
  if(e.key === "Enter") {
    enter_the_cropper();
  }
}

document.addEventListener("turbo:unload", function() {
  document.removeEventListener('keyup', cropper_escape_or_enter, false);
});

document.addEventListener("turbo:load", function() {
  let pages = document.querySelectorAll('.page');

  if( pages.length > 0 ) {
    document.addEventListener('keyup', cropper_escape_or_enter, false);
    document.querySelectorAll('button.escape').forEach(function(b){
      b.addEventListener('click', escape_the_cropper, false);
      b.parentElement.style.visibility="hidden";
    });
    document.querySelectorAll('button.enter').forEach(function(b){
      b.addEventListener('click', enter_the_cropper, false);
      b.parentElement.style.visibility="hidden";
    });
  }
  for( let p of pages ) {
    p.addEventListener('click', function(e) {
      this.scrollIntoView();
      console.log(`starting cropper on page ${p.dataset.pageid}`);
      document.getElementById("stamp_page_id").value = p.dataset.pageid;

      cr = new Cropper (e.target, {
        aspectRatio: 36 / 9,
        dragMode: 'crop',
        autoCropArea: 0.45,
        zoomable: false,

      }, false);


      e.target.addEventListener('ready', function () {
        let my_icons = e.target.parentElement.previousElementSibling
        my_icons.style.visibility = "visible";
      });
    });
  }

});

//--------------------------------------------------------------------------------

function escape_the_cropper_tag(){
  if(cr){
    document.querySelector(".tagPeopleIcons").style.visibility = "hidden";
    console.log("escaped from cropper")
    cr.destroy();
  }
}

function enter_the_cropper_tag() {
  if(cr){
    document.querySelector(".tagPeopleIcons").style.visibility = "hidden";
    console.log("done with cropper");
    let values = cr.getCropBoxData();
    console.log(values);
    console.log(typeof values);
    for(let key of ["left", "top", "width", "height"] ) {
      let new_value = values[key];
      document.getElementById("tagging_" + key).value = new_value;
      console.log(`setting tagging_${key} to ${new_value}`);
    }
    cr.destroy();
    console.log("escaped from cropper")
    document.getElementsByTagName('form')[0].submit();
  }
}

function cropper_escape_or_enter_tag(e){
  if(e.key === "Escape") {
    escape_the_cropper_tag()
  }
  if(e.key === "Enter") {
    enter_the_cropper_tag();
  }
}

document.addEventListener("turbo:load", function() {
  let tagPersonPages = document.querySelectorAll('.tagPersonPage');

  if( tagPersonPages.length > 0 ) {
    document.addEventListener('keyup', cropper_escape_or_enter_tag, false);
    document.querySelectorAll('button.escape').forEach(function(b){
      b.addEventListener('click', escape_the_cropper_tag, false);
      b.parentElement.style.visibility="hidden";
    });
    document.querySelectorAll('button.enter').forEach(function(b){
      b.addEventListener('click', enter_the_cropper_tag, false);
      b.parentElement.style.visibility="hidden";
    });
  }
  for( let p of tagPersonPages ) {
    p.addEventListener('click', function(e) {
      this.scrollIntoView();

      document.querySelector(".tagPeopleIcons").style.visibility = "visible";

      cr = new Cropper (e.target, {
        aspectRatio: 3/3,
        dragMode: 'crop',
        autoCropArea: 0.25,
        zoomable: false,
        viewMode: 2,
      }, false);


      e.target.addEventListener('ready', function () {
    
      });
    });
  }

});